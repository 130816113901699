.landing-page .carousel-caption {
  h1, p {
    text-transform: uppercase;
  }
}

button.dim {
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}

.nav {
  > li > a {
    color: #a7b1c2;
    font-weight: 600;
    padding: 14px 20px 14px 25px;
  }
  &.navbar-right > li > a {
    color: #999c9e;
  }
  > li.active > a {
    color: #fff;
  }
}

.navbar-default .nav > li > a {
  &:focus, &:hover {
    background-color: #293846;
    color: #fff;
  }
}

.nav.navbar-top-links > li > a {
  &:focus, &:hover {
    background-color: transparent;
  }
}

.navbar-default {
  background-color: transparent;
}

.nav > li > a i {
  margin-right: 6px;
}

.navbar {
  border: 0;
}

.navbar-default {
  border-color: #2f4050;
}

.navbar-top-links li {
  display: inline-block;
  &:last-child {
    margin-right: 40px;
  }
}

.body-small .navbar-top-links li:last-child {
  margin-right: 0;
}

.navbar-top-links li a {
  padding: 20px 10px;
  min-height: 50px;
}

.dd-item > button, code {
  white-space: nowrap;
}

.nav-header {
  padding: 33px 25px;
  background: url(patterns/header-profile.png) no-repeat;
}

.nav > li.active {
  border-left: 4px solid #3385FF;
  background: #293846;
}

.nav-header {
  a {
    color: #DFE4ED;
  }
  .text-muted {
    color: #8095a8;
  }
}

.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: inline-table;
}

.nav.navbar-top-links a {
  font-size: 14px;
}

.navbar-form-custom .form-control {
  background: rgba(0, 0, 0, 0);
  border: none;
  font-size: 14px;
  height: 60px;
  margin: 0;
  z-index: 2000;
}

.count-info .label {
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 6px;
  top: 12px;
}

.navbar-default .landing_link a {
  background: #3385FF;
  color: #fff;
  &:hover {
    background: #3385FF !important;
    color: #fff;
  }
  span.label {
    background: #fff;
    color: #3385FF;
  }
}

.navbar-fixed-top {
  transition-duration: .4s;
  border-bottom: 1px solid #e7eaec !important;
  z-index: 2030;
  background: #f3f3f4;
}

.navbar-static-top {
  background: #f3f3f4;
}

.fixed-nav #wrapper {
  margin-top: 0;
}

body {
  &.fixed-nav #wrapper #page-wrapper {
    margin-top: 60px;
  }
  &.top-navigation.fixed-nav #wrapper #page-wrapper {
    margin-top: 0;
  }
  &.fixed-nav.fixed-nav-basic {
    .navbar-fixed-top {
      left: 220px;
    }
    &.body-small .navbar-fixed-top {
      left: 0;
    }
  }
}

.body-small .navbar-fixed-top {
  margin-left: 0;
}

body.canvas-menu .nav-header {
  padding: 33px 25px;
}

.top-navigation #page-wrapper {
  margin-left: 0;
}

.white-bg {
  .navbar-fixed-top, .navbar-static-top {
    background: #fff;
  }
}

.top-navigation {
  .navbar {
    margin-bottom: 0;
  }
  .nav > li {
    > a {
      padding: 15px 20px;
      color: #676a6c;
    }
    a {
      &:focus, &:hover {
        background: #fff;
        color: #3385FF;
      }
    }
    &.active {
      background: #fff;
      border: none;
      > a {
        color: #3385FF;
      }
    }
  }
  .navbar-right {
    margin-right: 10px;
  }
}

.btn-default {
  &.active, &:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
  }
}

.btn-white {
  &.active, &:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
  }
}

.top-navigation .navbar-brand {
  background: #3385FF;
  color: #fff;
  padding: 15px 25px;
}

.btn-info {
  &.active, &:active {
    background-image: none;
  }
}

.btn-primary {
  &.active, &:active {
    background-image: none;
  }
}

.btn-success {
  &.active, &:active {
    background-image: none;
  }
}

.top-navigation .navbar-top-links li:last-child {
  margin-right: 0;
}

.canvas-menu #page-wrapper {
  margin: 0;
}

.fixed-nav #wrapper.top-navigation {
  margin-top: 50px;
}

.top-navigation {
  &.fixed-nav #wrapper {
    margin-top: 50px;
  }
  .wrapper.wrapper-content {
    padding: 40px;
  }
}

.body-small .top-navigation .wrapper.wrapper-content, .top-navigation.body-small .wrapper.wrapper-content {
  padding: 40px 0;
}

.navbar-toggle {
  background-color: #3385FF;
  color: #fff;
  padding: 6px 12px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .top-navigation .navbar-header {
    display: block;
    float: none;
  }
}

.menu-visible-lg, .menu-visible-md {
  display: none !important;
}

@media (min-width: 1200px) {
  .menu-visible-lg {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .menu-visible-md {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .menu-visible-lg, .menu-visible-md {
    display: block !important;
  }
}

button.dim {
  display: inline-block;
}

.btn {
  border-radius: 3px;
}

.btn-primary.btn-outline {
  color: #3385FF;
}

.btn-success.btn-outline {
  color: #1c84c6;
}

.btn-info.btn-outline {
  color: #23c6c8;
}

.btn-warning.btn-outline {
  color: #f8ac59;
}

.btn-info.btn-outline:hover, .btn-primary.btn-outline:hover, .btn-success.btn-outline:hover, .btn-warning.btn-outline:hover {
  color: #fff;
}

.btn-primary {
  background-color: #3385FF;
  border-color: #3385FF;
  color: #FFF;
  &.active {
    background-color: #0073B1;
    border-color: #0073B1;
    color: #FFF;
    &:focus, &:hover {
      background-color: #0073B1;
      border-color: #0073B1;
      color: #FFF;
    }
  }
  &:active {
    background-color: #0073B1;
    border-color: #0073B1;
    color: #FFF;
    &:focus, &:hover {
      background-color: #0073B1;
      border-color: #0073B1;
      color: #FFF;
    }
  }
  &:focus, &:hover {
    background-color: #0073B1;
    border-color: #0073B1;
    color: #FFF;
  }
  &.active[disabled] {
    background-color: #0073B1;
    border-color: #0073B1;
  }
  &[disabled] {
    background-color: #0073B1;
    border-color: #0073B1;
    &:active, &:focus, &:hover {
      background-color: #0073B1;
      border-color: #0073B1;
    }
  }
}

.btn-success {
  background-color: #1c84c6;
  border-color: #1c84c6;
  color: #FFF;
  &.active {
    background-color: #1a7bb9;
    border-color: #1a7bb9;
    color: #FFF;
    &:focus, &:hover {
      background-color: #1a7bb9;
      border-color: #1a7bb9;
      color: #FFF;
    }
  }
  &:active {
    background-color: #1a7bb9;
    border-color: #1a7bb9;
    color: #FFF;
    &:focus, &:hover {
      background-color: #1a7bb9;
      border-color: #1a7bb9;
      color: #FFF;
    }
  }
  &:focus, &:hover {
    background-color: #1a7bb9;
    border-color: #1a7bb9;
    color: #FFF;
  }
  &.active[disabled] {
    background-color: #1f90d8;
    border-color: #1f90d8;
  }
  &[disabled] {
    background-color: #1f90d8;
    border-color: #1f90d8;
    &:active, &:focus, &:hover {
      background-color: #1f90d8;
      border-color: #1f90d8;
    }
  }
}

.btn-info {
  background-color: #23c6c8;
  border-color: #23c6c8;
  color: #FFF;
  &.active {
    background-color: #21b9bb;
    border-color: #21b9bb;
    color: #FFF;
    &:focus, &:hover {
      background-color: #21b9bb;
      border-color: #21b9bb;
      color: #FFF;
    }
  }
  &:active {
    background-color: #21b9bb;
    border-color: #21b9bb;
    color: #FFF;
    &:focus, &:hover {
      background-color: #21b9bb;
      border-color: #21b9bb;
      color: #FFF;
    }
  }
  &:focus, &:hover {
    background-color: #21b9bb;
    border-color: #21b9bb;
    color: #FFF;
  }
  &.active[disabled] {
    background-color: #26d7d9;
    border-color: #26d7d9;
  }
  &[disabled] {
    background-color: #26d7d9;
    border-color: #26d7d9;
    &:active, &:focus, &:hover {
      background-color: #26d7d9;
      border-color: #26d7d9;
    }
  }
}

.btn-default {
  color: inherit;
  background: #fff;
  border: 1px solid #e7eaec;
}

.btn-link {
  &.active, &:active {
    background-image: none;
  }
}

.btn-warning {
  &.active, &:active {
    background-image: none;
  }
}

.btn-default {
  &.active {
    color: inherit;
    border: 1px solid #d2d2d2;
    &:focus, &:hover {
      color: inherit;
      border: 1px solid #d2d2d2;
    }
  }
  &:active {
    color: inherit;
    border: 1px solid #d2d2d2;
    &:focus, &:hover {
      color: inherit;
      border: 1px solid #d2d2d2;
    }
  }
  &:focus, &:hover {
    color: inherit;
    border: 1px solid #d2d2d2;
  }
  &.active[disabled] {
    color: #cacaca;
  }
  &[disabled] {
    color: #cacaca;
    &:active, &:focus, &:hover {
      color: #cacaca;
    }
  }
}

.btn-warning {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: #FFF;
  &.active {
    background-color: #f7a54a;
    border-color: #f7a54a;
    color: #FFF;
    &:focus, &:hover {
      background-color: #f7a54a;
      border-color: #f7a54a;
      color: #FFF;
    }
  }
  &:active {
    background-color: #f7a54a;
    border-color: #f7a54a;
    color: #FFF;
    &:focus, &:hover {
      background-color: #f7a54a;
      border-color: #f7a54a;
      color: #FFF;
    }
  }
  &:focus, &:hover {
    background-color: #f7a54a;
    border-color: #f7a54a;
    color: #FFF;
  }
  &.active[disabled] {
    background-color: #f9b66d;
    border-color: #f9b66d;
  }
  &[disabled] {
    background-color: #f9b66d;
    border-color: #f9b66d;
    &:active, &:focus, &:hover {
      background-color: #f9b66d;
      border-color: #f9b66d;
    }
  }
}

.btn-link {
  color: inherit;
  &.active, &:active, &:focus, &:hover {
    color: #3385FF;
    text-decoration: none;
  }
  &.active[disabled] {
    color: #cacaca;
  }
  &[disabled] {
    color: #cacaca;
    &:active, &:focus, &:hover {
      color: #cacaca;
    }
  }
}

.btn-white {
  color: inherit;
  background: #fff;
  border: 1px solid #e7eaec;
  &.active {
    color: inherit;
    border: 1px solid #d2d2d2;
    &:focus, &:hover {
      color: inherit;
      border: 1px solid #d2d2d2;
    }
  }
  &:active {
    color: inherit;
    border: 1px solid #d2d2d2;
    &:focus, &:hover {
      color: inherit;
      border: 1px solid #d2d2d2;
    }
  }
  &:focus, &:hover {
    color: inherit;
    border: 1px solid #d2d2d2;
  }
  &.active, &:active {
    background-image: none;
  }
  &.active[disabled] {
    color: #cacaca;
  }
  &[disabled] {
    color: #cacaca;
    &:active, &:focus, &:hover {
      color: #cacaca;
    }
  }
}

.form-control {
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}

.has-error .form-control:focus, .has-success .form-control:focus, .has-warning .form-control:focus, .navbar-collapse, .navbar-form {
  box-shadow: none;
}

.navbar-form-custom .form-control {
  &:focus, &:hover {
    box-shadow: none;
  }
}

.panel {
  box-shadow: none;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-large-dim {
  width: 90px;
  height: 90px;
  font-size: 42px;
}

button {
  &.dim {
    text-decoration: none;
    text-align: center;
    padding-top: 6px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 20px !important;
    &:active {
      top: 3px;
    }
  }
  &.btn-primary.dim {
    box-shadow: inset 0 0 0 #16987e, 0 5px 0 0 #16987e, 0 10px 5px #999;
    &:active {
      box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 3px #999;
    }
  }
  &.btn-default.dim {
    box-shadow: inset 0 0 0 #b3b3b3, 0 5px 0 0 #b3b3b3, 0 10px 5px #999;
    &:active {
      box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999;
    }
  }
  &.btn-warning.dim {
    box-shadow: inset 0 0 0 #f79d3c, 0 5px 0 0 #f79d3c, 0 10px 5px #999;
    &:active {
      box-shadow: inset 0 0 0 #f79d3c, 0 2px 0 0 #f79d3c, 0 5px 3px #999;
    }
  }
  &.btn-info.dim {
    box-shadow: inset 0 0 0 #1eacae, 0 5px 0 0 #1eacae, 0 10px 5px #999;
    &:active {
      box-shadow: inset 0 0 0 #1eacae, 0 2px 0 0 #1eacae, 0 5px 3px #999;
    }
  }
  &.btn-success.dim {
    box-shadow: inset 0 0 0 #1872ab, 0 5px 0 0 #1872ab, 0 10px 5px #999;
    &:active {
      box-shadow: inset 0 0 0 #1872ab, 0 2px 0 0 #1872ab, 0 5px 3px #999;
    }
  }
  &.dim:before {
    font-size: 50px;
    line-height: 1em;
    font-weight: 400;
    color: #fff;
    display: block;
    padding-top: 10px;
  }
}

.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: 'Open Sans';
  text-shadow: none;
  font-weight: 600;
}

button.dim:active:before {
  top: 7px;
  font-size: 50px;
}

.btn:focus {
  outline: 0 !important;
}

.label {
  font-size: 10px;
  padding: 3px 8px;
}

.label-primary {
  background-color: #3385FF;
  color: #FFF;
}

.label-success {
  background-color: #1c84c6;
  color: #FFF;
}

.label-warning {
  background-color: #f8ac59;
  color: #FFF;
}

.label-warning-light {
  background-color: #f8ac59;
  color: #fff;
}

.label-info {
  background-color: #23c6c8;
  color: #FFF;
}

.label-white {
  background-color: #FFF;
  color: #5E5E5E;
  background-color: #2A2E36;
  color: #8B91A0;
}

.h-150 {
  min-height: 150px;
}

.h-200 {
  min-height: 200px;
}

.img-circle {
  border-radius: 50%;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
  }
}

.vertical-align {
  div {
    display: inline-block;
    vertical-align: middle;
  }
  h2, h3 {
    margin: 0;
  }
}

.glyphicon.fa {
  font-family: FontAwesome;
}

.inline {
  display: inline-block !important;
}

.input-s-sm {
  width: 120px;
}

.input-s {
  width: 200px;
}

.input-s-lg {
  width: 250px;
}

.form-control {
  background-color: #FFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color .15s ease-in-out 0s, box-shadow .15s ease-in-out 0s;
  font-size: 14px;
  &:focus {
    border-color: #3385FF !important;
  }
}

.has-success .form-control {
  border-color: #3385FF;
}

.has-warning .form-control {
  border-color: #f8ac59;
}

.has-error .form-control {
  border-color: #ed5565;
}

.has-success .control-label {
  color: #3385FF;
}

.has-warning .control-label {
  color: #f8ac59;
}

.has-error .control-label {
  color: #ed5565;
}

.author-info {
  text-align: center;
}

.dd-item > button {
  text-align: center;
  &:before {
    text-align: center;
  }
}

.vertical-timeline-icon {
  text-align: center;
}

.dd, .dd-list {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.dd, .dd-item > button, .dd-list {
  position: relative;
}

.dd {
  font-size: 13px;
  line-height: 20px;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item, .dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-item > button {
  display: block;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  overflow: hidden;
  border: 0;
  background: 0 0;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  &:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-indent: 0;
  }
  &[data-action=collapse]:before {
    content: '-';
  }
}

.dd-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#wrapper, body {
  overflow-x: hidden;
}

.google-map {
  height: 300px;
}

label.error {
  color: #cc5965;
  display: inline-block;
  margin-left: 5px;
}

.form-control.error {
  border: 1px dotted #cc5965;
}

.img-container {
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.author-info {
  color: #676a6c;
  font-size: 11px;
  margin-top: 5px;
}

.vertical-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #f1f1f1;
  }
}

.vertical-timeline-content .btn {
  float: right;
}

#vertical-timeline.light-timeline:before {
  background: #e7eaec;
}

@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em;
    &:before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  i {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -9px;
  }
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px;
    i {
      margin-left: -12px;
      margin-top: -10px;
    }
  }
}

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: .25em;
  padding: 1em;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  h2 {
    font-weight: 400;
    margin-top: 4px;
  }
  p {
    margin: 1em 0;
    line-height: 1.6;
  }
  .vertical-date {
    float: left;
    font-weight: 500;
  }
}

.vertical-date small {
  color: #3385FF;
  font-weight: 400;
}

.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-content {
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 13px;
    }
  }
}

@media only screen and (min-width: 1170px) {
  .center-orientation {
    .vertical-timeline-content {
      margin-left: 0;
      padding: 1.6em;
      width: 45%;
      &::before {
        top: 24px;
        left: 100%;
        border-color: transparent transparent transparent #fff;
      }
      .btn {
        float: left;
      }
    }
    .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
      float: right;
      .btn {
        float: right;
      }
    }
    .vertical-timeline-content .vertical-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 2px;
      font-size: 14px;
    }
    .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
      &::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent #fff transparent transparent;
      }
      .vertical-date {
        left: auto;
        right: 122%;
        text-align: right;
      }
    }
  }
}

.slick-next:before, .slick-prev:before {
  color: #3385FF !important;
}

body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #2f4050;
  font-size: 13px;
  color: #676a6c;
  height: 100%;
}

html {
  height: 100%;
}

.clear {
  display: block;
  overflow: hidden;
}

a {
  cursor: pointer;
  &:focus, &:hover {
    text-decoration: none;
  }
}

.border-bottom {
  border-bottom: 1px solid #e7eaec !important;
}

.font-bold {
  font-weight: 600;
}

#wrapper {
  width: 100%;
}

.wrapper {
  padding: 0 20px;
}

.wrapper-content {
  padding: 20px 10px 40px;
}

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  position: relative !important;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 240px;
    min-height: 2002px;
  }
}

.ibox-content {
  h1, h2, h3, h4, h5 {
    margin-top: 5px;
  }
}

.ibox-title {
  h1, h2, h3, h4, h5 {
    margin-top: 5px;
  }
}

.link-block, .nav.navbar-top-links .link-block a {
  font-size: 12px;
}

.panel.blank-panel {
  background: 0 0;
  margin: 0;
}

.no-top-border {
  border-top: 0 !important;
}

.border-left, .border-left-right, .border-right {
  border-top: none;
  border-bottom: none;
}

.ibox-content.text-box {
  padding-bottom: 0;
  padding-top: 15px;
}

.border-left-right {
  border-left: 1px solid #e7eaec;
  border-right: 1px solid #e7eaec;
}

.border-left {
  border-left: 1px solid #e7eaec;
  border-right: none;
}

.border-right {
  border-left: none;
  border-right: 1px solid #e7eaec;
}

.full-width {
  width: 100% !important;
}

.link-block {
  padding: 10px;
  a {
    font-size: 10px;
    color: inherit;
  }
}

img.circle-border {
  border: 6px solid #FFF;
  border-radius: 50%;
}

.login-panel {
  margin-top: 25%;
}

.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

code {
  background-color: #F9F2F4;
  border-radius: 4px;
  color: #ca4440;
  font-size: 90%;
  padding: 2px 4px;
}

.ibox-content, .ibox-title {
  background-color: #fff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
  &.collapsed .ibox-content {
    display: none;
  }
  &:after, &:before {
    display: table;
  }
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  color: inherit;
  padding: 15px 20px 20px;
  border-width: 1px 0;
  clear: both;
}

.star-mail {
  width: 40px;
}

.ibox-title {
  h5 {
    display: inline-block;
    font-size: 14px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    float: left;
  }
  .label {
    float: left;
    margin-left: 4px;
  }
}

.file-box {
  float: left;
}

.gray-bg {
  background-color: #f3f3f4;
}

.white-bg {
  background-color: #fff;
}

.navy-bg {
  background-color: #3385FF;
  color: #fff;
}

.navy-bg-present {
  background-color: #1cf400;
  color: #fff;
}

.panel-primary {
  border-color: #3385FF;
}

.panel-success {
  border-color: #1c84c6;
}

.panel-info {
  border-color: #23c6c8;
}

.panel-warning {
  border-color: #f8ac59;
}

.panel-title {
  font-size: inherit;
}

.text-navy {
  color: #3385FF;
}

.text-primary {
  color: inherit;
}

.text-success {
  color: #1c84c6;
}

.text-info {
  color: #23c6c8;
}

.text-warning {
  color: #f8ac59;
}

.text-muted {
  color: #888;
}

.text-white {
  color: #fff;
}

.contact-box, .light-navbar .navbar.navbar-static-top {
  background-color: #fff;
}

a.close-canvas-menu {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1011;
  color: #a7b1c2;
  &:hover {
    color: #fff;
  }
}

.close-canvas-menu {
  display: none;
}

.canvas-menu .close-canvas-menu {
  display: block;
}

.full-height {
  height: 100%;
}

.p-xs {
  padding: 10px;
}

.p-sm {
  padding: 15px;
}

.p-m {
  padding: 20px;
}

.p-md {
  padding: 25px;
}

.p-lg {
  padding: 30px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-n {
  margin: 0 !important;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.block {
  display: block !important;
}

.search-form {
  margin-top: 10px;
}

.contact-box {
  border: 1px solid #e7eaec;
  padding: 20px;
  margin-bottom: 20px;
  > a {
    color: inherit;
  }
}

.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
  h1 {
    font-size: 170px;
  }
}

.wrapper .middle-box {
  margin-top: 140px;
}

.timeline-item {
  .content, .date i {
    border-left: 1px solid #e7eaec;
    border-top: 1px solid #e7eaec;
  }
}

ul.clear-list:first-child {
  border-top: none !important;
}

.timeline-item {
  .date {
    i {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      width: 30px;
      text-align: center;
      border-bottom: 1px solid #e7eaec;
      background: #f8f8f8;
    }
    text-align: right;
    width: 110px;
    position: relative;
    padding-top: 30px;
  }
  .content {
    padding-top: 10px;
    min-height: 100px;
    &:hover {
      background: #f6f6f6;
    }
  }
}

.file-box {
  width: 220px;
}

.category-list li {
  a {
    color: #666;
    display: block;
    padding: 5px 0;
  }
  display: block;
  i {
    margin-right: 8px;
    color: #3d4d5d;
  }
  a {
    .text-navy {
      color: #3385FF;
    }
    .text-primary {
      color: #1c84c6;
    }
    .text-info {
      color: #23c6c8;
    }
    .text-warning {
      color: #F8AC59;
    }
  }
}

.file {
  border: 1px solid #e7eaec;
  padding: 0;
  background-color: #fff;
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
}

.file-control {
  margin-right: 10px;
}

.file {
  .icon, .image {
    height: 100px;
    overflow: hidden;
  }
  .icon {
    padding: 15px 10px;
    text-align: center;
  }
}

.file-control {
  color: inherit;
  font-size: 11px;
  &.active {
    text-decoration: underline;
  }
}

.file {
  .icon i {
    font-size: 70px;
    color: #dadada;
  }
  .file-name {
    padding: 10px;
    background-color: #f8f8f8;
    border-top: 1px solid #e7eaec;
  }
}

.file-name small {
  color: #676a6c;
}

.vote-icon, .vote-title {
  display: block;
}

.mail-search {
  max-width: 300px;
}

.file-list li {
  padding: 5px 10px;
  font-size: 11px;
  border-radius: 2px;
  border: 1px solid #e7eaec;
  margin-bottom: 5px;
  a {
    color: inherit;
    &:hover {
      color: #3385FF;
    }
  }
}

.user-friends img {
  width: 42px;
  height: 42px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.mail-box {
  background-color: #fff;
  border: 1px solid #e7eaec;
  border-top: 0;
  padding: 0;
  margin-bottom: 20px;
}

.mail-body, .mail-text {
  border-top: 1px solid #e7eaec;
}

.mail-box-header {
  background-color: #fff;
  border: 1px solid #e7eaec;
  border-bottom: 0;
  padding: 30px 20px 20px;
  h2 {
    margin-top: 0;
  }
}

.mail-body {
  padding: 20px;
}

.project-actions {
  text-align: right;
  vertical-align: middle;
}

.project-title a {
  font-size: 14px;
  color: #676a6c;
  font-weight: 600;
}

.project-files li a {
  font-size: 11px;
  color: #676a6c;
  margin-left: 10px;
  line-height: 22px;
}

.article h1, .blog h2 {
  font-weight: 700;
}

.contact-type {
  width: 20px;
  color: #c1c3c4;
}

.blog {
  .btn, h5 {
    margin: 0 0 5px;
  }
}

.article {
  h1 {
    font-size: 48px;
    color: #2F4050;
  }
  p {
    font-size: 15px;
    line-height: 26px;
  }
}

.article-title {
  text-align: center;
  margin: 40px 0 100px;
}

.article .ibox-content {
  padding: 40px;
}

.vote-item {
  border-top: 1px solid #e7eaec;
}

.social-feed-box {
  border: 1px solid #e7eaec;
  background: #fff;
  margin-bottom: 15px;
}

.article .social-feed-box {
  margin-bottom: 0;
  border-bottom: none;
  &:last-child {
    margin-bottom: 0;
    border-bottom: 1px solid #e7eaec;
  }
  p {
    font-size: 13px;
    line-height: 18px;
  }
}

.social-comment .social-comment, .vote-info, .vote-title {
  margin-left: 45px;
}

.social-avatar {
  padding: 15px 15px 0;
  img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
}

.social-body {
  padding: 15px;
  img {
    margin-bottom: 10px;
  }
}

.social-comment {
  &:first-child {
    margin-top: 0;
  }
  margin-top: 15px;
}

.vote-item {
  padding: 20px 25px;
  background: #fff;
  &:last-child {
    border-bottom: 1px solid #e7eaec;
  }
  &:hover {
    background: #fbfbfb;
  }
}

.vote-actions {
  float: left;
  width: 30px;
  margin-right: 15px;
  text-align: center;
  font-weight: 600;
  a {
    color: #3385FF;
    font-weight: 600;
  }
}

.vote-title {
  color: inherit;
  &:focus, &:hover {
    color: inherit;
  }
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 2px;
}

.vote-info {
  color: #b4b6b8;
  font-size: 12px;
  a {
    color: #b4b6b8;
    font-size: 12px;
    margin-right: 10px;
    &:hover {
      color: #3385FF;
    }
  }
}

.vote-icon {
  text-align: right;
  font-size: 38px;
  color: #e8e9ea;
  &.active {
    color: #3385FF;
  }
}

body {
  &.body-small .vote-icon {
    display: none;
  }
  &.landing-page {
    color: #676a6c;
    font-family: 'Open Sans', helvetica, arial, sans-serif;
    background-color: #fff;
  }
}

@-webkit-keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}


@keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}


@-webkit-keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@-webkit-keyframes sk-waveStretchDelay {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}


@keyframes sk-waveStretchDelay {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}


@-webkit-keyframes sk-wanderingCubeMove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    transform: translateX(42px) rotate(-90deg) scale(0.5);
  }

  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  }

  50.1% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}


@keyframes sk-wanderingCubeMove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    transform: translateX(42px) rotate(-90deg) scale(0.5);
  }

  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  }

  50.1% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}


@-webkit-keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}


@keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}


@-webkit-keyframes sk-chasingDotsRotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@keyframes sk-chasingDotsRotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@-webkit-keyframes sk-chasingDotsBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@keyframes sk-chasingDotsBounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@-webkit-keyframes sk-threeBounceDelay {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@keyframes sk-threeBounceDelay {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@-webkit-keyframes sk-circleBounceDelay {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@keyframes sk-circleBounceDelay {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 100%, 70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}


@keyframes sk-cubeGridScaleDelay {
  0%, 100%, 70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}


@-webkit-keyframes sk-innerCircle {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@keyframes sk-innerCircle {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@-webkit-keyframes sk-circleFadeDelay {
  0%, 100%, 39% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}


@keyframes sk-circleFadeDelay {
  0%, 100%, 39% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}


.landing-page {
  span.navy {
    color: #3385FF;
  }
  p.text-color {
    color: #676a6c;
  }
  a.navy-link {
    color: #3385FF;
    text-decoration: none;
    &:hover {
      color: #3385FF;
    }
  }
  section p {
    color: #aeaeae;
    font-size: 13px;
  }
  h1 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 200;
  }
  .navy-line {
    width: 60px;
    height: 1px;
    margin: 60px auto 0;
    border-bottom: 2px solid #3385FF;
  }
  .navbar-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
    > .container {
      padding-right: 0;
      padding-left: 0;
    }
    .navbar {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .navbar-default {
    &.navbar-scroll {
      background-color: #fff;
      border-color: #fff;
      padding: 15px 0;
    }
    background-color: transparent;
    border-color: transparent;
    transition: all .3s ease-in-out 0s;
    .nav li a {
      color: #fff;
      font-family: 'Open Sans', helvetica, arial, sans-serif;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
  .navbar-nav > li > a {
    padding-top: 25px;
    border-top: 6px solid transparent;
  }
  .navbar-default {
    .navbar-nav > {
      .active > a {
        background: 0 0;
        color: #fff;
        border-top: 6px solid #3385FF;
        &:hover {
          background: 0 0;
          color: #fff;
          border-top: 6px solid #3385FF;
        }
      }
      li > a {
        &:focus, &:hover {
          color: #3385FF;
          background: inherit;
        }
      }
      .active > a:focus {
        background: 0 0;
        color: #fff;
      }
    }
    &.navbar-scroll .navbar-nav > .active > a:focus {
      background: 0 0;
      color: inherit;
    }
    .navbar-brand {
      &:focus, &:hover {
        background: #3385FF;
        color: #fff;
      }
      color: #fff;
      height: auto;
      display: block;
      font-size: 14px;
      background: #3385FF;
      padding: 15px 20px;
      border-radius: 0 0 5px 5px;
      font-weight: 700;
      transition: all .3s ease-in-out 0s;
    }
  }
  .navbar-scroll.navbar-default .nav li a {
    color: #676a6c;
    &:hover {
      color: #3385FF;
    }
  }
  .navbar-wrapper .navbar.navbar-scroll {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #e7eaec;
    border-radius: 0;
  }
  .nav > li {
    &.active {
      border: none;
      background: inherit;
    }
    > a {
      padding: 25px 10px 15px;
    }
  }
  .navbar-scroll .navbar-nav > li > a {
    padding: 20px 10px;
  }
  .navbar-fixed-top {
    border: none !important;
    &.navbar-scroll {
      border-bottom: 1px solid #e7eaec !important;
    }
  }
  .navbar.navbar-scroll .navbar-brand {
    margin-top: 15px;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
    height: auto;
  }
  .header-back {
    height: 470px;
    width: 100%;
    background-position: 50% 0;
    background-repeat:  no-repeat;
  }
  .carousel {
    height: 470px;
  }
  .carousel-caption {
    z-index: 10;
  }
  .carousel .item {
    height: 470px;
    background-color: #777;
  }
  .carousel-inner > .item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 470px;
  }
  .carousel-fade {
    .carousel-inner {
      .item {
        opacity: 0;
        -webkit-transition-property: opacity;
        transition-property: opacity;
      }
      .active {
        opacity: 1;
        &.left, &.right {
          left: 0;
          opacity: 0;
          z-index: 1;
        }
      }
      .next.left, .prev.right {
        opacity: 1;
      }
    }
    .carousel-control {
      z-index: 2;
    }
  }
  .carousel-control {
    &.left, &.right {
      background: 0 0;
    }
    width: 6%;
  }
  .carousel-inner {
    .container {
      position: relative;
    }
    overflow: visible;
  }
  .carousel-caption {
    position: absolute;
    top: 100px;
    left: 0;
    bottom: auto;
    right: auto;
    text-align: left;
    &.blank {
      top: 140px;
    }
  }
  .carousel-image {
    position: absolute;
    right: 10px;
    top: 150px;
  }
  .carousel-caption {
    h1 {
      font-weight: 700;
      font-size: 38px;
      text-shadow: none;
      letter-spacing: -1.5px;
    }
    p {
      font-weight: 700;
      text-shadow: none;
    }
  }
  .caption-link {
    color: #fff;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 400;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
  .features-block, .features-text {
    margin-top: 40px;
  }
  .features {
    small {
      color: #3385FF;
    }
    h2 {
      font-size: 18px;
      margin-top: 5px;
    }
  }
  .features-icon {
    color: #3385FF;
    font-size: 40px;
  }
  .navy-section {
    margin-top: 60px;
    background: #3385FF;
    color: #fff;
    padding: 20px 0;
  }
  .gray-section {
    background: #f4f4f4;
    margin-top: 60px;
  }
  .team-member {
    text-align: center;
    img {
      margin: auto;
    }
  }
  .social-icon a {
    background: #3385FF;
    color: #fff;
    padding: 4px 8px;
    height: 28px;
    width: 28px;
    display: block;
    border-radius: 50px;
    &:hover {
      background: #3385FF;
    }
  }
  .img-small {
    height: 88px;
    width: 88px;
  }
  .btn-primary {
    font-weight: 600;
    font-size: 14px;
  }
  .testimonials {
    padding-top: 80px;
    padding-bottom: 90px;
    background-color: #3385FF;
    background-image: url(../assets/img/landing/avatar_all.png);
  }
  .contact {
    background-image: url(../assets/img/landing/word_map.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-top: 60px;
  }
  section {
    &.timeline {
      padding-bottom: 30px;
    }
    &.comments {
      padding-bottom: 80px;
    }
  }
  .comments-avatar {
    margin-top: 25px;
    margin-left: 22px;
    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .btn-info.btn-outline:hover, .btn-primary.btn-outline:hover, .btn-success.btn-outline:hover, .btn-warning.btn-outline:hover {
    color: #fff;
  }
  .btn-primary {
    background-color: #3385FF;
    border-color: #3385FF;
    color: #FFF;
    &.active, &:active, &:focus, &:hover {
      background-color: #3385FF;
      border-color: #3385FF;
      color: #FFF;
    }
    padding: 10px 20px;
    &.active, &:active {
      background-image: none;
    }
    &.active[disabled] {
      background-color: #1dc5a3;
      border-color: #1dc5a3;
    }
    &[disabled] {
      background-color: #1dc5a3;
      border-color: #1dc5a3;
      &:active, &:focus, &:hover {
        background-color: #1dc5a3;
        border-color: #1dc5a3;
      }
    }
  }
}

@media (min-width: 768px) {
  .landing-page {
    .navbar-wrapper {
      margin-top: 20px;
      .container {
        padding-right: 15px;
        padding-left: 15px;
      }
      .navbar {
        padding-right: 0;
        padding-left: 0;
        border-radius: 4px;
      }
    }
    .carousel-caption p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}

@media (max-width: 992px) {
  .landing-page .carousel-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .landing-page {
    .carousel-caption {
      left: 5%;
      top: 80px;
      &.blank {
        left: 5%;
        top: 80px;
      }
      h1 {
        font-size: 28px;
      }
    }
    .navbar.navbar-scroll .navbar-brand {
      margin-top: 6px;
    }
    .navbar-default {
      background-color: #fff;
      border-color: #fff;
      padding: 15px 0;
      .navbar-nav > .active > a:focus {
        background: 0 0;
        color: inherit;
      }
      .nav li a {
        color: #676a6c;
        &:hover {
          color: #3385FF;
        }
      }
    }
    .navbar-wrapper .navbar {
      padding-top: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #e7eaec;
      border-radius: 0;
    }
    .nav > li > a {
      padding: 25px 10px 15px;
    }
    .navbar-nav > li > a {
      padding: 20px 10px;
    }
    .navbar .navbar-brand {
      margin-top: 6px;
      border-radius: 5px;
      font-size: 12px;
      padding: 10px;
      height: auto;
    }
    .navbar-wrapper .navbar {
      padding-left: 15px;
      padding-right: 5px;
    }
    .navbar-default .navbar-nav > .active > a {
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
    .carousel-control {
      display: none;
    }
    .navbar .navbar-header {
      display: block;
      float: none;
      .navbar-toggle {
        background-color: #fff;
        padding: 9px 10px;
        border: none;
      }
    }
  }
}
