@media screen and (min-width: 768px) {
  .dropdown-menu {
    float: none;
    font-size: 20px;
    width: 70%;
    margin: 0 15%;
  }
  .search-tool ul {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-content {
    width: 70%;
    margin: 0 15%;
    position: absolute;
    top: 13%;
    left: auto;
    right: auto;
    font-size: 22px;
    height: 50px;
    background-color: #eee;
    color: black;
    opacity: 1.0;
  }
}

@media screen and (max-width: 767px) {
  .dropdown-menu {
    float: none;
    font-size: 20px;
    width: 96%;
    margin: 0 2%;
  }
  .search-tool ul {
    width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-content {
    width: 96%;
    margin: 0 2%;
    position: absolute;
    top: 10%;
    left: auto;
    right: auto;
    font-size: 22px;
    height: 50px;
    background-color: #eee;
    color: black;
    opacity: 1.0;
  }
}
